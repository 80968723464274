import React from "react"
import BPLocationPage from "@geome/bp-location-page"
import { initLog } from "@geome/fe-log"
import { version } from "@geome/bp-location-page/package.json"

const appVersion = version
const deployEnv = process.env.DEPLOY_ENV

initLog({
  logglyKey: "89f85593-200b-45dd-a38f-0a12d24e0a62",
  appName: "bpdirectoryapi",
  deployEnv,
  appVersion,
})

const LocationPage = (props) => {
  return <BPLocationPage {...props} />
}

export default LocationPage
