import React from "react"
import { SetupContexts, PageWrapper, TopLocations, GeographicList } from "@geome/bp-directory-pages"

const GeographicDirectoryPage = (props) => {
  return (
    <SetupContexts {...props.setupContextsProps}>
      <PageWrapper {...props.pageWrapperProps}>
        <TopLocations {...props.topLocationsProps} />
        <GeographicList {...props.geographicListProps} />
      </PageWrapper>
    </SetupContexts>
  )
}

export default GeographicDirectoryPage
